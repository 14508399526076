<template>

  <section :class="`wrapper1`">
      <div class="wrapper1-data" :style="{backgroundColor:`#fbfbfb`}">
      <div class="wrapper1-data-texts">
        <h3>{{ $t(aboutData.name) }}</h3>
        <p v-html="resumeHtml(aboutData.description)"></p>
      </div>
      <div class="wrapper1-data-links">
        <read-more :changeState="changeState" :aboutData="aboutData" :intl="aboutData.name"/>
        <ul>
          <li v-for="(menu, i) in reorderedMenu(aboutMenu)" :key="i">
            <router-link v-if="menu.name=='Sobre Nós'" :to="{ name: 'About', params: { about: menu.name.toLowerCase().replace(/\s+/g, '-'), menu: menu.name }}">
              <span class="d-block icon2-chevron-thin-right" :style="{backgroundColor:aboutData.color, color:`#fff`}"></span>
              <span :style="{color:aboutData.color}">{{ menu.name }}</span>
            </router-link>
            <router-link v-else @click.native="changeContent()" :to="{ name: 'About', params: { about: menu.name.toLowerCase().replace(/\s+/g, '-'), menu: menu.name }}">
              <span class="d-block icon2-chevron-thin-right" :style="{backgroundColor:aboutData.color, color:`#fff`}"></span>
              <span :style="{color:aboutData.color}">{{ menu.name }}</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import ReadMore from '@/components/shared/Read-more-about'
export default {
  name: 'SolutionsWrapper1',
  props: {
    aboutData: {
      type: Object,
      required: true
    },
    aboutMenu: {
      type: Array,
      required: true
    }
  },
  components: {
    ReadMore
  },
  data () {
    return {
      elementoPrioritario: 'Sobre Nós'
    }
  },
  methods: {
    resumeHtml (str) {
      // eslint-disable-next-line
      return (str ? str : '').replace(/<[^>]*>?/gm, '').replace(/^(.{159}[^\s]*).*/, '$1') + '...'
    },
    changeState () {
      this.$emit('close-about-list')
    },
    changeContent () {
      this.$emit('close-about-contentfull-list')
    },
    reorderedMenu (payload) {
      return payload.sort((a, b) => (a.name === this.elementoPrioritario ? -1 : 1))
    },
    getFirstWord (text) {
      if (!text) return ''
      return text.split(' ')[0]
    }
  }
}
</script>

<style lang="scss" scoped>
img.logo-list {
  height:33px !important;
  margin-left: -12px;
}
.HideWrapper1 {
  display: none !important;
}
.ShowWrapper1 {
  display: flex !important;
}
.wrapper1 {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
.wrapper1-data {
  flex-basis: 100%;
  color: #3e3e3ede;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 45px;
  box-sizing: border-box;
  padding-top: 75px;
  transition: background .26s linear;

  .wrapper1-data-texts {
    display: flex;
    flex-direction: column;
    h3 {
      text-transform: uppercase;
      font-size: 1.8em;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 50px;
    }
    p {
      font-size: 17px;
      font-family: "gilroy", sans-serif;
      font-weight: 100 !important;
    }
  }
  .wrapper1-data-links {
    box-sizing: border-box;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 10%;
    button:hover {
      cursor: pointer;
    }
    button {
      margin: 25px 0;
      border: 2px solid #FFF;
      border-radius: 25px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      color: #fff;
      width: 60%;
      outline: none;

      .plus {
        display: flex;
        height: 20px;
        width: 20px;
        justify-content: center;
        align-items: center;
        font-size: 7pt;
        background: #fff;
        border-radius: 50%;
        margin-right: 4px;
      }

      span {
        font-size: 14px;
        // padding:0 0 13px 5px;
      }
    }
    ul {
      list-style: none;
      span {
        color: #fff;
      }
      li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #fff;
        a{
          // margin-left: 2%;
          font-weight: 100 !important;
          text-transform: uppercase !important;
        }
      }
      li:hover {
        cursor: pointer;
      }

      li:last-child {
        border-bottom-width: 0;
      }
    }
  }
}

.wrapper1-image {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all .26s linear;
}

a {
  display: flex;
  width: 100%;
  text-decoration: none;
  align-items: center;
  padding: 7px 0;
  font-weight: bolder;
}

.d-block {
  display: flex;
  background-color: #fff;
  border-radius: 50%;
  padding: 2px;
  margin-right: 10px;
  font-size: 16pt;
}
@media (max-width: 325px){
  .wrapper1-data {
    .wrapper1-data-texts {
      padding: 0px 15px 0 15px;
      margin-top: -150px !important;
    }
  }
  .wrapper1-data-links {
    height: 40%;
    padding-left: 5%;
    button {
      margin: 15px 0 !important;
      padding: 5px;
      width: 130px !important;
      height: 35px !important;
      span {
        font-size: 16px !important;
        padding-left: 5px;
      }
   .plus {
        display: flex;
        height: 20px;
        width: 20px;
        justify-content: center;
        align-items: center;
        font-size: 7pt;
        background: #fff;
        border-radius: 50%;
        margin-right: 4px;
      }
    }
  }
}
@media (max-width: 768px) {
  .wrapper1 {
    display: flex;
    flex-direction: column;
    height: 100vh !important;
  }
  .wrapper1-data {
    width: 100% !important;
    flex: 4 !important;
    .wrapper1-data-texts {
      padding: 0px 15px 0 15px;
      margin-top: -120px;
      h1 {
        font-size: 30px;
        margin-bottom: 25px;
      }
      p {
        font-size: 16px;
      }
    }
  }
  .wrapper1-data-links {
    padding: 0 15px 0 15px !important;
      button {
      margin: 15px 0 !important;
      padding: 5px;
      width: 95px !important;
      height: 26px !important;
      span {
        font-size: 12px !important;
        padding-left: 5px;
      }
      .plus {
        display: flex;
        height: 20px;
        width: 15px !important;
        justify-content: center;
        align-items: center;
        font-size: 7pt;
        background: #fff;
        border-radius: 50%;
        margin:-2.5px 4px 0 0;
      }
    }
  }
  .wrapper1-image {
    width: 100% !important;
    flex: 1;
    transition: all .26s linear;
  }
  .logo-list {
    width:100% !important;
  }
}
.logo-list {
  width:250px;
  margin: 20px 0;
}

.chevron {
  font-size: 20pt;
  background-color: #ffF;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media(max-width: 768px){
    span.plus.icon-plus.UTalent:before, .UTalent,
    span.plus.icon-plus.UMember:before, .UMember,
    span.plus.icon-plus.UKnow:before, .UKnow,
    span.plus.icon-plus.UDigital::before, .UDigital,
    span.plus.icon-plus.UContact::before, .UContact {
        color: #d83541 !important;
        margin-left: -4px;
    }
}

</style>
