<template>
  <div class="abouts">
    <about-component :about-data="data" :about-menu="otherAbouts"/>
  </div>
</template>
<script>
// import SolutionConfig from '@/utils/solutions-config.js'
import AboutComponent from '@/components/About/Index'
import axios from 'axios'
export default {
  name: 'About',
  metaInfo: {
    title: 'Sobre Nós - Ucall'
  },
  components: {
    'about-component': AboutComponent
  },
  data () {
    return {
      data: {},
      dataList: [],
      nameMenu: 'Sobre Nós'
    }
  },
  async mounted () {
    this.$router.push({
      name: 'About',
      params: {
        about: this.nameMenu.toLowerCase().replace(/\s+/g, '-'),
        menu: this.nameMenu
      }
    })
    this.about = this.$route.params.menu
    await this.getByName()
    await this.get()
  },
  watch: {
    $route (to, from) {
      this.about = to.params.menu
      this.getByName()
    },
    '$i18n.locale': {
      handler: async function (after, before) {
        await this.getByName(this.$route.params.menu)
        await this.get()
      },
      deep: true
    }
  },
  methods: {
    async getByName () {
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/about`, {
          params: {
            $limit: 1,
            $filter: `data/name/iv eq '${this.about}'`
          },
          headers: {
            'X-Resolve-Urls': 'logo,image,video',
            'X-Languages': this.$i18n.locale,
            'X-Flatten': true
          }
        })
        .then((resp) => {
          this.data = { ...{ id: resp.data.items[0].id }, ...resp.data.items[0].data }
        })
        .catch((error) => {
          /* eslint-disable no-console */
          console.log(error)
          /* eslint-enable no-console */
        })
    },
    async get () {
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/about`, {
          params: {
            $orderby: 'created desc',
            $limit: 8
          },
          headers: {
            'X-Resolve-Urls': 'logo,image,video',
            'X-Languages': this.$i18n.locale,
            'X-Flatten': true
          }
        })
        .then((resp) => {
          let data = []
          resp.data.items.forEach((element) => {
            data.push({ ...{ id: element.id }, ...element.data })
          })
          this.dataList = data
        })
        .catch((error) => {
          /* eslint-disable no-console */
          console.log(error)
          /* eslint-enable no-console */
        })
    }
  },
  computed: {
    solutionDetail () {
      const { menu } = this.$route.params
      return this.dataList.filter((item) => item.name === menu)[0]
    },
    otherAbouts () {
      return this.dataList.filter((item) => item.name !== 'Sobre Nós')
    }
    /*     otherAbouts () {
          const { menu } = this.$route.params
          console.log('PARAMS2-----', this.$route)
          console.log('PARAMS2', menu)
          console.log('this.dataList2', this.dataList)
          return this.dataList.filter((item) => item.name !== menu)
        } */
  }
}
</script>

<style lang="scss" scoped>
.abouts {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
